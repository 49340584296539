import React from "react";
import { withAlgoliaConnect } from '../../../HOC';
import {withProps,compose} from 'recompose'
import { PostSlider, Heading } from "../../../components";
import PropTypes from "prop-types";

const INDEXNAME='prod_BLOG';

const Component = () => {
  return (
    <section id='publications'>
      <Heading heading="Publications" size="sm" />
      <div className="tt-wrap">
      <PostSlider  slideMargin={40} items={4}/>
      </div>
    </section>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default compose(
  withProps(()=>({filters:'_category:publication'})),
  withAlgoliaConnect(INDEXNAME)
)(Component);
