import React,{Fragment} from "react";
import { StaticQuery, graphql } from "gatsby";
import { Grid } from "../../../components";
import type { IGraphqlFeatured } from "../../../definitions";
import { prepareFeatured } from "./helpers"
import {isMobile} from 'react-device-detect'
const { ListItem, List } = Grid;



export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          limit: 16
          filter: {
            frontmatter: {
              templateKey: { eq: "gallery-item" }
              isFeatured: { eq: true }
            }
          }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                images {
                  alt
                  src
                }
                title
                showDetail
                isPublished
                hideImage
                artist {
                  firstname
                  othernames
                  lastname
                }
              }
            }
          }
        }
      }
    `}
    render={(data: IGraphqlFeatured) => {
      const featuredItems: Array<any> = prepareFeatured(data);
      return (
        <section id="gallery-list-section">
          <div>
            <List  name={'featured'} header='Featured' framed={isMobile} subtitle={'Some Featured Works'} subTitleLink={{
              url:'/collection/featured',
              name: 'View All'
            }} cols={4}>
              {featuredItems &&
              featuredItems.map((featured: { id: string }) => (
                <ListItem  navigateWithClick itemStyle={1} key={featured.id} {...featured} />
              ))}
            </List>
          </div>
        </section>

      );
    }}
  />
);
