// @flow
import type { IGraphqlFeatured } from "../../../definitions";
import { prepareImage, getFullnames } from "../../../util";
const TRANSFORMER = "f_auto,q_auto/b_white,c_pad,h_420,e_sharpen/";

export const prepareFeatured = (data: IGraphqlFeatured): any => {
  const { edges } = data.allMarkdownRemark;
  return edges.map(edge => {
    const {
      id,
      frontmatter,
      fields: { slug: url }
    } = edge.node;
    const {
      title,
      artist,
      images: [{ src }],
      showDetail,
      hideImage,
      isPublished
    } = frontmatter;
    return {
      id,
      title,
      url,
      hideImage,
      showDetail,
      isPublished,
      subtitle: getFullnames(artist),
      src: prepareImage(src, TRANSFORMER)
    };
  });
};
