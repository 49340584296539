// @flow
import { Carousel } from "../../../components";
import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { prepareItems } from "./helpers";
import PropTypes from "prop-types";

const TRANSFORMERS={
  "FA-1163":"c_crop,g_xy_center,h_1301,w_2114,x_1655,y_1000,e_sharpen/",
  "FAk2009.00371":"c_crop,g_xy_center,h_1377,w_2365,x_2950,y_1549,e_sharpen/",
  "FAk2009.00320":"c_crop,g_xy_center,h_1523,w_2213,x_1850,y_4147,e_sharpen/",
  "FAk2009.00054":"c_crop,h_1200,w_1898,e_sharpen/",
  "FAk2009.00063":"c_crop,h_1856,w_3618,e_sharpen/",
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: { eq: "gallery-item" }
              intro: { addToIntro: { eq: true } }
            }
          }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                artType
                accessionNumber
                materialMedium
                culture
                artist {
                  firstname
                  othernames
                  lastname
                }
                originCountry
                images {
                  alt
                  src
                }
                intro {
                  linkToGallery
                  linkToItem
                  titleField
                  introTitle
                  introDescription
                  descriptionField
                  subtitleField
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Carousel
          carouselItems={prepareItems(data,TRANSFORMERS)}
        />
      )
    }}
  />
);
