// @flow
import type { IGraphqlCarouselItem } from "../../../definitions";
import {lowerCase} from 'lodash';
import { prepareImage, getFullnames } from "../../../util";

const prepareActions = (item: {
  fields: { slug: string },
  intro: { linkToGallery: boolean, linkToItem: boolean }
}) => {
  let actions = [];
  if (item.intro.linkToItem) {
    actions = [
      ...actions,
      ...[{ name: "View Item", url: item.fields.slug, isPrimary: true }]
    ];
  }
  if (item.intro.linkToGallery) {
    actions = [...actions, ...[{ name: "Visit Gallery", url: "/collection" }]];
  }
  return actions;
};

const normalizeSubtitle = payload => {
  return !payload || lowerCase(payload) === "unknown"
    ? "Artist Unknown"
    : payload;
};

export const prepareItems = (
  data: IGraphqlCarouselItem,
  transformers: Object = {}
): any => {
  const { edges } = data.allMarkdownRemark;
  return edges.map(edge => {
    const { frontmatter, fields } = edge.node;
    const { intro, images, accessionNumber } = frontmatter;
    const {
      introTitle,
      titleField,
      subtitleField,
      introDescription,
      descriptionField
    } = intro;
    const title = introTitle || frontmatter[titleField];
    const subtitle = subtitleField === "artist"
      ? getFullnames(frontmatter[subtitleField])
      : frontmatter[subtitleField];
    const description = introDescription || frontmatter[descriptionField];
    const src = prepareImage(images[0].src, transformers[accessionNumber]);
    const actions = prepareActions({ fields, intro });
    return { title, description, subtitle:normalizeSubtitle(subtitle), src, actions };
  });
};
