//flow
import React, { Fragment } from "react";
import {
  TraditionalContent,
  ModernContent,
  CollectionContent
} from "../../page-components/about";
import { Publications, Featured, SlideShow } from "../../page-components/home";
import { getAdminToolsForContent } from "../../util";
import { withAuth } from "../../HOC";

const Component = ({ isAuth }: any) => {
  return (
    <Fragment>
      <div id="body-content">
        <SlideShow />
        <CollectionContent
          name={"collection"}
          wrap={"wrap"}
          actions={[
            {
              name: "Visit Gallery",
              url: "/collection",
              btnStyle: "btn-primary-bordered"
            },
            getAdminToolsForContent("intro", isAuth)
          ]}
          isLeft
        />
        <TraditionalContent
          name={"traditional"}
          wrap={"wrap"}
          actions={[
            {
              name: "Read More",
              url: "/about/#about-traditional",
              isPrimary: true
            },
            {
              name: "Visit Gallery",
              url: "/collection/traditional",
              btnStyle: "btn-primary-bordered"
            },
            getAdminToolsForContent("traditional", isAuth)
          ]}
          isRight
          showExcerpt
        />
        <ModernContent
          name={"modern"}
          wrap={"wrap"}
          actions={[
            {
              name: "Visit Gallery",
              url: "/collection/modern",
              btnStyle: "btn-primary-bordered"
            },
            getAdminToolsForContent("modern", isAuth)
          ]}
          isLeft
          showExcerpt
        />
        <Featured />
      </div>
      <Publications />
    </Fragment>
  );
};

export default withAuth("admin")(Component);
